import { Box, Card, Icon, Typography } from '@mui/material';
import MDButton from 'components/MDButton';
import { useSocket } from 'hooks/AdminSocketContext';
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { selectLiveSupports } from 'store/main/liveSupportsSlice';

import MDTypography from 'components/MDTypography';
import AdminSupportItem from './SupportItems/AdminSupportItem';
import UserSupportItem from './SupportItems/UserSupportItem';

function LatestSupports({ isUser }) {
  const { t } = useTranslation();
  const liveSupports = useSelector(selectLiveSupports);

  const [sortedLiveSupports, setSortedLiveSupports] = React.useState([]);

  useEffect(() => {
    const sortedLiveSupports = liveSupports.sort((a, b) => {
      if (a.messagesInfo.length > 0 && b.messagesInfo.length === 0) {
        return -1;
      }
      else if (a.messagesInfo.length === 0 && b.messagesInfo.length > 0) {
        return 1;
      }
      if (a.messagesInfo.length > 0 && b.messagesInfo.length > 0) {
        return new Date(b.messagesInfo[0]?.createdDate) - new Date(a.messagesInfo[0]?.createdDate);
      }
      else {
        return new Date(b.createdDate) - new Date(a.createdDate);
      }
    })
    setSortedLiveSupports(sortedLiveSupports);
  }, [liveSupports]);

  return (
    <Card sx={{ padding: 3, gap: 2 }}>
      <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
        <Icon>history</Icon>
        <Typography variant="h5">Son Canlı Destekler</Typography>
      </Box>

      {isUser && (
        <MDButton component={Link} to={`${t("supportRoute")}/${t("new")}`} variant="outlined" color="info">
          Yeni Canlı Destek Başlat <Icon fontSize="large" sx={{ marginLeft: 1 }}>add_circle_outlined</Icon>
        </MDButton>
      )}

      <Box sx={{ gap: 1, display: "flex", flexDirection: "column" }}>
        {
          sortedLiveSupports.length > 0 ?
            sortedLiveSupports.map((support) => (
              isUser ?
                <UserSupportItem key={support.id} support={support} />
                :
                <AdminSupportItem key={support.id} support={support} />
            ))
            : <MDTypography variant="body2">
              Henüz canlı destek başlatılmamış.
            </MDTypography>}
      </Box>
    </Card>
  );
}

export default LatestSupports;
