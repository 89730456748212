import React from 'react';
import { Card } from '@mui/material';
import { selectLiveSupportsById } from 'store/main/liveSupportsSlice';
import { useSelector } from 'react-redux';
import MDTypography from 'components/MDTypography';
import SendMessageSection from './SendMessageSection';
import Messages from './Messages';
import { useUserSocket } from 'hooks/UserSocketContext';
import MDBox from 'components/MDBox';
import OnlineIndicator from './OnlineIndicator';
import { useParams } from 'react-router-dom';


const ChatRoom = ({ isUser }) => {
  const { id } = useParams();
  const support = useSelector((state) => selectLiveSupportsById(state, id));



  return (
    <Card sx={{ display: "flex", flexDirection: "column", gap: 2, padding: 4, height: "100%" }}>
      <MDBox>
        <MDTypography variant="h4">{support.supportHeader}</MDTypography>
        {isUser && <OnlineIndicator />}
      </MDBox>
      <Messages />
      <SendMessageSection />
    </Card>
  );
};

export default ChatRoom;