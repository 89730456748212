import { Icon } from '@mui/material';
import { grey } from '@mui/material/colors';
import MDAvatar from 'components/MDAvatar';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import dayjs from 'dayjs';
import { imageProxy } from 'features/api/proxy';
import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { selectLiveSupportsById } from 'store/main/liveSupportsSlice';

function Messages() {
  const { id } = useParams();
  const messages = useSelector((state) => selectLiveSupportsById(state, id)).messagesInfo;

  // Ref to keep track of the last message
  const lastMessageRef = useRef(null);

  // Scroll to the last message when messages update
  useEffect(() => {
    setTimeout(() => {
      if (lastMessageRef.current) {
        lastMessageRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }, 200);
  }, [messages, id]);

  const renderMessage = (message, index) => {
    if (message.url && message.url !== "") {
      return <MDBox
        key={message.id}
        sx={{ display: "flex", justifyContent: message.sentFrom === "user" ? "flex-start" : "flex-end", gap: 1, }}
        ref={index === messages.length - 1 ? lastMessageRef : null}
      >
        <MDBox>
          <MDBox py={1} px={2} borderRadius="xl" bgColor={message.sentFrom === "user" ? grey[900] : "info"} color={"white"} sx={{ maxWidth: "450px", width: "450px" }}>
            <img src={`${imageProxy}/${message.url}`} alt="file" style={{ maxWidth: "100%", height: "auto", marginTop: "10px", borderRadius: "12px", cursor: "pointer" }} />
            <MDTypography color={"white"} variant="h5">
              {message.message}
            </MDTypography>
          </MDBox>
          <MDBox sx={{ display: "flex", justifyContent: "space-between" }}>
            <MDTypography variant={"subtitle2"} sx={{ alignItems: "center", display: "flex", gap: .5 }}>
              <Icon>access_time</Icon> {dayjs(message.createdDate).format("HH:mm")}
            </MDTypography>
            <MDTypography variant={"subtitle2"} sx={{ alignItems: "center", display: "flex", gap: .5 }}>
              <Icon>done_all</Icon>
            </MDTypography>
          </MDBox>
        </MDBox>
      </MDBox>
    }
    return <MDBox
      key={message.id}
      sx={{ display: "flex", justifyContent: message.sentFrom === "user" ? "flex-start" : "flex-end", gap: 1, }}
      ref={index === messages.length - 1 ? lastMessageRef : null}
    >
      <MDBox>
        <MDBox py={1} px={2} borderRadius="xl" bgColor={message.sentFrom === "user" ? grey[900] : "info"} color={"white"} sx={{ maxWidth: "250px", width: "250px" }}>
          <MDTypography color={"white"} variant="h5">
            {message.message}
          </MDTypography>
        </MDBox>
        <MDBox sx={{ display: "flex", justifyContent: "space-between" }}>
          <MDTypography variant={"subtitle2"} sx={{ alignItems: "center", display: "flex", gap: .5 }}>
            <Icon>access_time</Icon> {dayjs(message.createdDate).format("HH:mm")}
          </MDTypography>
          <MDTypography variant={"subtitle2"} sx={{ alignItems: "center", display: "flex", gap: .5 }}>
            <Icon>done_all</Icon>
          </MDTypography>
        </MDBox>
      </MDBox>
    </MDBox>
  }

  return (
    <MDBox border={`3px solid ${grey[200]}`} borderRadius="xl" sx={{ display: "flex", flexGrow: 1, flexShrink: 1, height: "200px", justifyContent: "flex-end", flexDirection: "column-reverse", padding: 2 }}>
      <MDBox
        sx={{
          overflowY: "auto",
          "::-webkit-scrollbar": {
            width: "8px",
          },
          "::-webkit-scrollbar-thumb": {
            backgroundColor: grey[400],
            borderRadius: "4px",
          },
          "::-webkit-scrollbar-thumb:hover": {
            backgroundColor: grey[100],
          },
          "::-webkit-scrollbar-track": {
            backgroundColor: grey[100],
            borderRadius: "4px",
          },
        }}
      >
        {messages && messages.length > 0 && [...messages].sort((a, b) => a.id - b.id).map((message, index) => (
          renderMessage(message, index)
        ))}
      </MDBox>
    </MDBox>
  )
}

export default Messages;
