/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 PRO React components

// @mui icons
import Icon from "@mui/material/Icon";
import DashboardIcon from "@mui/icons-material/Dashboard";
import AudiotrackIcon from "@mui/icons-material/Audiotrack";
import PaymentIcon from "@mui/icons-material/Payment";
import { GrAnalytics } from "react-icons/gr";

// Images
import AdminSongsDashboard from "pages/admin/songs/AdminSongsDashboard";
import AdminCategoriesDashboard from "pages/admin/categories/AdminCategoriesDashboard";
import AdminComposersDashboard from "pages/admin/composers/AdminComposersDashboard";
import AdminInstrumentsDashboard from "pages/admin/instruments/AdminInstrumentsDashboard";
import AdminLessonsDashboard from "pages/admin/lessons/AdminLessonsDashboard";
import AdminMusiciansDashboard from "pages/admin/musicians/AdminMusiciansDashboard";
import AdminUsersDashboard from "pages/admin/users/AdminUsersDashboard";
import AdminSongListDashboard from "pages/admin/songList/AdminSongListDashboard";
import AdminCoursesDashboard from "pages/admin/courses/AdminCoursesDashboard";

import MDAvatar from "components/MDAvatar";

import Iyzico from "assets/icons/iyzico2.svg";
import Paypal from "assets/icons/paypal2.svg";
import IyzicoProductsDashboard from "pages/admin/iyzico/products/IyzicoProductsDashboard";
import IyzicoPricingPlanDahsboard from "pages/admin/iyzico/pricingPlan/IyzicoPricingPlanDahsboard";
import IyzicoSubscriptionDashboard from "pages/admin/iyzico/subscriptions/IyzicoSubscriptionDashboard";
import AdminPricingPlansDashboard from "pages/admin/pricingPlans/AdminPricingPlansDashboard";
import AdminSubscriptionsDashboard from "pages/admin/subscriptions/AdminSubscriptionsDashboard";
import AdminInsCoursesDashboard from "pages/admin/instructorCourses/AdminInstCoursesDashboard";
import AdminInsLessonsDashboard from "pages/admin/instructorLessons/AdminInsLessonsDashboard";
import AdminInsApplicationsDashboard from "pages/admin/instructorApplications/AdminInsApplicationsDashboard";
import AdminDashboard from "pages/admin/dashboard/AdminDashboard";
import PayPalPricingPlansDashboard from "pages/admin/paypal/pricingPlans/PayPalPricingPlansDashboard";
import PayPalSubscriptionsDashboard from "pages/admin/paypal/subscriptions/PayPalSubscriptionsDashboard";
import PaypalProductsDashboard from "pages/admin/paypal/products/PaypalProductsDashboard";
import AdminInstructorsDashboard from "pages/admin/instructors/AdminInstructersDashboard";
import CouponCodesDashboard from "pages/admin/couponCodes/CouponCodesDashboard";
import VideoAnalyzeDashboard from "pages/analytics/videoAnalyze/VideoAnalyzeDashboard";

//collapse icons
import { FaChalkboardTeacher } from "react-icons/fa";
import { MdOutlinePlayLesson } from "react-icons/md";
import { FaClipboardUser, FaUsers } from "react-icons/fa6";
import { FaVideo } from "react-icons/fa";
import { BsMusicNoteList } from "react-icons/bs";
import { MdFeedback } from "react-icons/md";
import { IoPricetag } from "react-icons/io5";
import { BsPersonVcardFill } from "react-icons/bs";
import { PiBarcodeBold } from "react-icons/pi";
import { BiSolidCategoryAlt } from "react-icons/bi";
import { PiMusicNotesFill } from "react-icons/pi";
import { FaGuitar } from "react-icons/fa";
import { TbPencil } from "react-icons/tb";
import { MdLibraryMusic } from "react-icons/md";
import { RiCoupon2Fill } from "react-icons/ri";
import { IoSettings } from "react-icons/io5";
import { GrConfigure } from "react-icons/gr";
import ConfigurationDashboard from "pages/admin/SettingsCollapse/ConfigurationDashboard/ConfigurationDashboard";
import SettingsDashboard from "pages/admin/SettingsCollapse/SettingsDashboard/SettingsDashboard";
import AdminSupportDashboard from "pages/admin/support/AdminSupportDashboard";

const routes = [
  {
    type: "href",
    name: "dashboardName",
    route: "dashboardRoute",
    key: "dashboardKey",
    icon: <DashboardIcon />,
    component: <AdminDashboard />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "instructorCollapse",
    key: "instructorCollapse",
    icon: <AudiotrackIcon />,
    collapse: [
      {
        name: "instructorsName",
        route: "instructorsRoute",
        key: "instructorsKey",
        icon: <FaChalkboardTeacher style={{ color: "rgb(255,255,255)", marginLeft: "10px", }} />,
        component: <AdminInstructorsDashboard />,
      },
      {
        name: "instructorCourseName",
        route: "instructorCoursesRoute",
        key: "instructorCoursesKey",
        icon: <MdOutlinePlayLesson style={{ color: "rgb(255,255,255)", marginLeft: "10px", }} />,
        component: <AdminInsCoursesDashboard />,
      },
      {
        name: "instructorLessonsName",
        route: "instructorLessonsRoute",
        key: "instructorLessonsKey",
        icon: <FaVideo style={{ color: "rgb(255,255,255)", marginLeft: "10px", }} />,
        component: <AdminInsLessonsDashboard />,
      },
      {
        name: "instructorApplicationsName",
        route: "instructorApplicationsRoute",
        key: "instructorApplicationsKey",
        icon: <FaClipboardUser style={{ color: "rgb(255,255,255)", marginLeft: "10px", }} />,
        component: <AdminInsApplicationsDashboard />,
      }
    ]
  },
  {
    type: "collapse",
    name: "songsCollapse",
    key: "songsCollapse",
    icon: <AudiotrackIcon />,
    collapse: [
      {
        name: "instrumentsName",
        route: "instrumentsRoute",
        key: "instrumentsKey",
        icon: <FaGuitar style={{ color: "rgb(255,255,255)", marginLeft: "10px", }} />,
        component: <AdminInstrumentsDashboard />,
      },
      {
        name: "categoriesName",
        route: "categoriesRoute",
        key: "categoriesKey",
        icon: <BiSolidCategoryAlt style={{ color: "rgb(255,255,255)", marginLeft: "10px", }} />,
        component: <AdminCategoriesDashboard />,
      },
      {
        name: "composersName",
        route: "composersRoute",
        key: "composersKey",
        icon: <TbPencil style={{ color: "rgb(255,255,255)", marginLeft: "10px", }} />,
        component: <AdminComposersDashboard />,
      },
      {
        name: "musiciansName",
        route: "musiciansRoute",
        key: "musiciansKey",
        icon: <MdLibraryMusic style={{ color: "rgb(255,255,255)", marginLeft: "10px", }} />,
        component: <AdminMusiciansDashboard />,
      },


      {
        name: "songsName",
        route: "songsRoute",
        key: "songsKey",
        icon: <PiMusicNotesFill style={{ color: "rgb(255,255,255)", marginLeft: "10px", }} />,
        component: <AdminSongsDashboard />,
      },
      {
        name: "songListName",
        route: "songListRoute",
        key: "songListKey",
        icon: <PiMusicNotesFill style={{ color: "rgb(255,255,255)", marginLeft: "10px", }} />,
        component: <AdminSongListDashboard />,
      },
    ]
  },
  {
    type: "collapse",
    name: "courseCollapse",
    key: "courseCollapse",
    icon: <Icon fontSize="medium">cast_for_education</Icon>,
    collapse: [
      {
        name: "coursesName",
        route: "coursesRoute",
        key: "coursesKey",
        icon: <MdOutlinePlayLesson style={{ color: "rgb(255,255,255)", marginLeft: "10px", }} />,
        component: <AdminCoursesDashboard />,
      },
      {
        name: "lessonsName",
        route: "lessonsRoute",
        key: "lessonsKey",
        icon: <FaVideo style={{ color: "rgb(255,255,255)", marginLeft: "10px", }} />,
        component: <AdminLessonsDashboard />,
      },
    ]
  },
  {
    type: "href",
    name: "usersName",
    route: "usersRoute",
    key: "usersKey",
    icon: <FaUsers style={{ color: "rgb(255,255,255)", marginLeft: "10px", }} />,
    component: <AdminUsersDashboard />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "subscriptionsCollapse",
    key: "subscriptionsCollapse",
    icon: <PaymentIcon />,
    collapse: [
      {
        name: "pricingPlansName",
        route: "pricingPlansRoute",
        key: "pricingPlansKey",
        icon: <IoPricetag style={{ color: "rgb(255,255,255)", marginLeft: "10px", }} />,
        component: <AdminPricingPlansDashboard />,
      },
      {
        name: "subscriptionsName",
        route: "subscriptionsRoute",
        key: "subscriptionsKey",
        icon: <BsPersonVcardFill style={{ color: "rgb(255,255,255)", marginLeft: "10px", }} />,
        component: <AdminSubscriptionsDashboard />,
      },
      {
        name: "couponCodesName",
        route: "couponCodesRoute",
        key: "couponCodesKey",
        icon: <RiCoupon2Fill style={{ color: "rgb(255,255,255)", marginLeft: "10px", }} />,
        component: <CouponCodesDashboard />,
      },
    ]
  },

  {
    type: "collapse",
    name: "iyzicoCollapse",
    key: "iyzicoCollapse",
    icon: <MDAvatar src={Iyzico} alt="Iyzico Logo" size="sm" />,
    collapse: [
      {
        name: "iyzicoProductsDahsboard",
        key: "iyzicoProductsKey",
        route: "iyzicoProductsRoute",
        icon: <PiBarcodeBold style={{ color: "rgb(255,255,255)", marginLeft: "10px", }} />,
        component: <IyzicoProductsDashboard />,
      },
      {
        name: "iyzicoPricingPlanName",
        key: "iyzicoPricingPlanKey",
        route: "iyzicoPricingPlanRoute",
        icon: <IoPricetag style={{ color: "rgb(255,255,255)", marginLeft: "10px", }} />,
        component: <IyzicoPricingPlanDahsboard />,
      },
      {
        name: "iyzicoSubscriptionName",
        key: "iyzicoSubscriptionKey",
        route: "iyzicoSubscriptionRoute",
        icon: <BsPersonVcardFill style={{ color: "rgb(255,255,255)", marginLeft: "10px", }} />,
        component: <IyzicoSubscriptionDashboard />,
      },
    ],
  },
  {
    type: "collapse",
    name: "paypalCollapse",
    key: "paypalCollapse",
    icon: <MDAvatar src={Paypal} alt="Paypal Logo" size="sm" />,
    collapse: [
      {
        name: "paypalProductsName",
        key: "paypalProductsKey",
        route: "paypalProductsRoute",
        icon: <PiBarcodeBold style={{ color: "rgb(255,255,255)", marginLeft: "10px", }} />,
        component: <PaypalProductsDashboard />,
      },
      {
        name: "paypalPricingPlanName",
        key: "paypalPricingPlanKey",
        route: "paypalPricingPlanRoute",
        icon: <IoPricetag style={{ color: "rgb(255,255,255)", marginLeft: "10px", }} />,
        component: <PayPalPricingPlansDashboard />,
      },
      {
        name: "paypalSubscriptionName",
        key: "paypalSubscriptionKey",
        route: "paypalSubscriptionRoute",
        icon: <BsPersonVcardFill style={{ color: "rgb(255,255,255)", marginLeft: "10px", }} />,
        component: <PayPalSubscriptionsDashboard />,
      },
    ]
  },
  {
    type: "collapse",
    name: "settingsCollapseName",
    key: "settingsCollapseKey",
    icon: <IoSettings fontSize="medium" />,
    collapse: [
      {
        name: "settingsName",
        key: "settingsKey",
        route: "settingsRoute",
        icon: <IoSettings style={{ color: "rgb(255,255,255)", marginLeft: "10px", }} />,
        component: <SettingsDashboard />,
      },
      {
        name: "configurationName",
        key: "configurationKey",
        route: "configurationRoute",
        icon: <GrConfigure style={{ color: "rgb(255,255,255)", marginLeft: "10px", }} />,
        component: <ConfigurationDashboard />,
      },
    ]
  },
  {
    type: "collapse",
    name: "analyticsCollapseName",
    key: "analyticsCollapseKey",
    icon: <GrAnalytics />,
    collapse: [
      {
        name: "videoAnalyzeName",
        key: "videoAnalyzeRoute",
        route: "videoAnalyzeRoute",
        icon: <BsMusicNoteList style={{ color: "rgb(255,255,255)", marginLeft: "10px", }} />,
        component: <VideoAnalyzeDashboard />,
      },
    ]
  },
  {
    type: "href",
    name: "supportName",
    route: "supportRoute",
    key: "supportKey",
    component: <AdminSupportDashboard />,
    icon: <Icon fontSize="medium">feedback_rounded</Icon>,
    noCollapse: true,
  },
];

export default routes;
