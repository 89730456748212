import StudentLayout from 'pages/main/layout/StudentLayout'
import React, { useEffect } from 'react'
import { Grid } from '@mui/material';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import NewConversation from './NewConversation';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { getLiveSupportsByQuery } from 'store/main/liveSupportsSlice';
import LoadingComponent from 'components/Loading';
import { selectLiveSupports } from 'store/main/liveSupportsSlice';
import { selectLiveSupportsById } from 'store/main/liveSupportsSlice';
import { UserSocketProvider } from 'hooks/UserSocketContext';
import LatestSupports from 'components/LiveFeedbacks/LatestSupports';
import ChatRoom from 'components/LiveFeedbacks/ChatRoom';

function SupportDashboard() {
  const { id } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(true);
  const liveSupports = useSelector((state) => selectLiveSupportsById(state, id));
  const { userName } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(getLiveSupportsByQuery()).then(() => setTimeout(() => {
      setLoading(false)
    }, 500));
  }, []);



  if (loading) {
    return <StudentLayout><LoadingComponent /></StudentLayout>
  }

  return (
    <UserSocketProvider>
      <StudentLayout>
        <Grid container spacing={4} sx={{ height: "100%", display: "flex", flexGrow: 1, flexShrink: 1, }}>
          <Grid item xs={4}>
            <LatestSupports isUser={true} />
          </Grid>
          <Grid item xs={8}>
            {
              liveSupports ? <ChatRoom isUser={true} /> : <NewConversation />
            }
          </Grid>
        </Grid>
      </StudentLayout>
    </UserSocketProvider>
  )
}

export default SupportDashboard