import { Icon } from '@mui/material'
import { grey } from '@mui/material/colors'
import colors from 'assets/theme/base/colors'
import rgba from 'assets/theme/functions/rgba'
import MDAvatar from 'components/MDAvatar'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import dayjs from 'dayjs'
import { imageProxy } from 'features/api/proxy'
import { useSocket } from 'hooks/AdminSocketContext'
import { useUserSocket } from 'hooks/UserSocketContext'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { getLiveSupports } from 'store/main/liveSupportsSlice'
import BaseSupportItem from './BaseSupportItem'

function AdminSupportItem({ key, support }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { socket } = useSocket();
  const { userName } = useSelector((state) => state.auth);

  const handleJoinRoom = () => {
    socket.emit("admin_join_room", { userName, liveSupportId: support.id });
    navigate(`${t("supportRoute")}/${support.id}`);
    dispatch(getLiveSupports());
  }

  return (
    <BaseSupportItem key={key} support={support} handleJoinRoom={handleJoinRoom} />
  )
}

export default AdminSupportItem