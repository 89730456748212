import { Icon } from '@mui/material';
import { grey } from '@mui/material/colors';
import MDAvatar from 'components/MDAvatar';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import dayjs from 'dayjs';
import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { selectLiveSupportsById } from 'store/main/liveSupportsSlice';

function Messages() {
  const { id } = useParams();
  const messages = useSelector((state) => selectLiveSupportsById(state, id)).messagesInfo;

  // Ref to keep track of the last message
  const lastMessageRef = useRef(null);

  // Scroll to the last message when messages update
  useEffect(() => {
    setTimeout(() => {
      if (lastMessageRef.current) {
        lastMessageRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }, 200);
  }, [messages, id]);

  const renderIconColor = (message) => {
    console.log(message);
    if (message.sentFrom === "admin" && message.adminSeenDate !== null) {
      return "info";
    }
    else if (message.sentFrom === "user" && message.userSeenDate === null) {
      return "info";
    }
  }

  return (
    <MDBox border={`3px solid ${grey[200]}`} borderRadius="xl" sx={{ display: "flex", flexGrow: 1, flexShrink: 1, height: "200px", justifyContent: "flex-end", flexDirection: "column-reverse", padding: 1 }}>
      <MDBox
        sx={{
          overflowY: "auto",
          "::-webkit-scrollbar": {
            width: "8px",
          },
          "::-webkit-scrollbar-thumb": {
            backgroundColor: "#888",
            borderRadius: "4px",
          },
          "::-webkit-scrollbar-thumb:hover": {
            backgroundColor: "#555",
          },
          "::-webkit-scrollbar-track": {
            backgroundColor: "#f1f1f1",
            borderRadius: "4px",
          },
        }}
      >
        {messages && messages.length > 0 && [...messages].sort((a, b) => a.id - b.id).map((message, index) => (
          <MDBox
            key={message.id}
            sx={{ display: "flex", justifyContent: message.sentFrom === "admin" ? "flex-start" : "flex-end", gap: 1, marginRight: 1 }}
            ref={index === messages.length - 1 ? lastMessageRef : null}
          >
            <MDBox>
              <MDBox py={1} px={2} borderRadius="xl" bgColor={message.sentFrom === "admin" ? "secondary" : "info"} color={"white"} sx={{ maxWidth: "250px", width: "250px" }}>
                <MDTypography color={"white"} variant="h5">
                  {message.message}
                </MDTypography>
              </MDBox>
              <MDBox sx={{ display: "flex", justifyContent: "space-between" }}>
                <MDTypography variant={"subtitle2"} sx={{ alignItems: "center", display: "flex", gap: .5 }}>
                  <Icon>access_time</Icon> {dayjs(message.createdDate).format("HH:mm")}z
                </MDTypography>
                <MDTypography variant={"subtitle2"} sx={{ alignItems: "center", display: "flex", gap: .5 }}>
                  <Icon color={message.sentFrom === "admin" ? (message.adminSeenDate !== "null" && "info") : (message.userSeenDate !== null && "info") || "inherit" }>done_all</Icon>
                </MDTypography>
              </MDBox>
            </MDBox>
          </MDBox>
        ))}
      </MDBox>
    </MDBox>
  )
}

export default Messages;
