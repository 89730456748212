import { Icon } from '@mui/material';
import colors from 'assets/theme/base/colors';
import rgba from 'assets/theme/functions/rgba';
import MDAvatar from 'components/MDAvatar';
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography';
import dayjs from 'dayjs';
import { imageProxy } from 'features/api/proxy';
import React from 'react'
import { useParams } from 'react-router-dom';

function BaseSupportItem({ handleJoinRoom, key, support }) {
  const { id } = useParams();
  const { info, grey } = colors;
  return (
    <MDBox
      onClick={() => handleJoinRoom()}
      borderRadius={"lg"}
      key={key}
      sx={{
        display: "flex",
        padding: 1,
        alignItems: "center",
        justifyContent: "space-between",
        border: `1px solid  ${parseInt(id) === support.id ? info.main : grey[200]}`,
        backgroundColor: parseInt(id) === support.id ? rgba(info.main, 0.3) : "white",
        "&:hover": {
          backgroundColor: parseInt(id) === support.id ? rgba(info.main, 0.4) : grey[100],
        },
        transition: "all 200ms ease-in-out",
      }}>
      <MDBox display={"flex"} gap={2} alignItems={"center"}>
        <MDAvatar src={support.userInfo?.url ? `${imageProxy}/${support.userInfo.url}` : "https://lasolist.com/uploads/default-cover/course-lesson-dark-1024x1024.png"} />
        <MDBox sx={{ display: "flex", flexDirection: "column" }}>
          <MDTypography variant="h5" sx={{ marginBottom: 0 }}>{support.supportHeader}</MDTypography>
          <MDTypography variant="body2">{support.messagesInfo[0]?.message}</MDTypography>
        </MDBox>
      </MDBox>
      {
        support.messagesInfo.length > 0 && (
          <MDBox display={"flex"} flexDirection={"column"} alignItems={"center"}>
            <MDTypography variant="body2">{dayjs(support.messagesInfo[0]?.createdDate).format("HH:mm")}</MDTypography>
            {
              support.messagesInfo?.filter((f) => f.sentFrom === "user").filter((f) => f.adminSeenDate === null).length > 0 ? (
                <MDTypography variant={"h6"} style={{
                  height: "100%",
                  aspectRatio: "1/1",
                  color: "white",
                  backgroundColor: info.main,
                  borderRadius: 999,
                  alignItems: "center",
                  justifyContent: "center",
                  textAlign: "center",
                }}>
                  {`${support.messagesInfo?.filter((f) => f.sentFrom === "user").filter((f) => f.adminSeenDate === null).length}`}
                </MDTypography>
              ) : <Icon color='info'>done_all</Icon>
            }
          </MDBox>
        )
      }
    </MDBox >
  )
}

export default BaseSupportItem