import { createSlice, createAsyncThunk, createEntityAdapter } from "@reduxjs/toolkit";
import axiosConfig from "features/api/axiosConfig";
import showToast from "hooks/useCustomToast";


export const getVideoAnalyze = createAsyncThunk(
  "messages/getVideoAnalyze",
  async () => {
    const response = await axiosConfig.get(`/api/analytics/video-analyzes`);

    let { data } = await response.data;
    return data;
  }
);

export const addMessage = createAsyncThunk(
  "messages/addMessage",
  async (message, { dispatch, getState }) => {
    try {
      const formData = new FormData();
      formData.append("message", message.message);
      formData.append("liveSupportId", message.liveSupportId);
      formData.append("sentFrom", message.sentFrom);
      formData.append("userName", message.userName);
      formData.append("createdDate", message.createdDate);
      formData.append("adminSeenDate", message.adminSeenDate);
      formData.append("url", message.url);



      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          boundary: formData._boundaries,
        },
      };


      const response = await axiosConfig.post(
        `/api/messages`,
        formData,
        config,
      );

      const { data } = response.data;

      if (data.success) {
        showToast("check_circle_icon", "message_sent_successfully", "success");
        return data;
      }

      return data;
    } catch (error) {
      console.log(error);
    }
  }
);

const messagesAdapter = createEntityAdapter({
  selectId: (message) => message.messageId,
});

export const {
  selectAll: selectMessages,
  selectById: selectMessagesById
} = messagesAdapter.getSelectors((state) => state.messages);


const messagesSlice = createSlice({
  name: "messages",
  initialState: messagesAdapter.getInitialState({
    searchText: "",
    routeParams: {},
    messageDialog: {
      type: "new",
      props: {
        open: false,
      },
      data: null,
    },
  }),
  reducers: {
    openNewMessageDialog: (state, action) => {
      state.messageDialog = {
        type: "new",
        props: {
          open: true,
        },
        data: null,
      };
    },
    closeNewMessageDialog: (state, action) => {
      state.messageDialog = {
        type: "new",
        props: {
          open: false,
        },
        data: null,
      };
    },
    openEditMessageDialog: (state, action) => {
      state.messageDialog = {
        type: "edit",
        props: {
          open: true,
        },
        data: action.payload,
      };
    },
    closeEditMessageDialog: (state, action) => {
      state.messageDialog = {
        type: "edit",
        props: {
          open: false,
        },
        data: null,
      };
    },
  },
  extraReducers: {
    [addMessage.fulfilled]: messagesAdapter.addOne,
    [getVideoAnalyze.fulfilled]: messagesAdapter.setAll,
  },
});


export const {
  openNewMessageDialog,
  closeNewMessageDialog,
  openEditMessageDialog,
  closeEditMessageDialog,
} = messagesSlice.actions;

export default messagesSlice.reducer;
