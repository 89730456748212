import MDBox from 'components/MDBox';
import { useUserSocket } from 'hooks/UserSocketContext';
import React from 'react'
import { useParams } from 'react-router-dom';

function OnlineIndicator() {
  const { id } = useParams();
  const { activeSupportRoom } = useUserSocket();

  return (
    <MDBox sx={{ display: "flex", alignItems: "center", gap: 1 }}>
      <MDBox sx={{ width: 16, height: 16, borderRadius: 8, transition: "300ms ease-in-out all" }} bgColor={activeSupportRoom == id ? "info" : "error"}></MDBox>
      {activeSupportRoom == id ? "Çevrimiçi" : "Çevrimdışı"}
    </MDBox>
  )
}

export default OnlineIndicator