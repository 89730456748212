import React, { useState } from 'react';
import { Box, TextField, Button, List, ListItem, ListItemText, Icon, Card, Grid } from '@mui/material';
import MDButton from 'components/MDButton';
import { useForm } from 'features/hooks';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { selectLiveSupportsById } from 'store/main/liveSupportsSlice';
import { useSelector } from 'react-redux';
import MDTypography from 'components/MDTypography';
import MDBox from 'components/MDBox';
import { useDispatch } from 'react-redux';
import { addMessage } from 'store/main/messagesSlice';
import io from 'socket.io-client';
import { useSocket } from 'hooks/AdminSocketContext';
import { getLiveSupports } from 'store/main/liveSupportsSlice';
import dayjs from 'dayjs';

const defaultFormState = {
  message: "",
  file: null,
};

const SendMessageSection = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const liveSupport = useSelector((state) => selectLiveSupportsById(state, id));
  const { form, handleChange, setForm, setInForm } = useForm(defaultFormState);
  const { userName } = useSelector((state) => state.auth);
  const { t } = useTranslation();

  const { socket } = useSocket();

  const handleSubmit = () => {
    if (form.message.trim() === "") return;
    console.log(form);
    let data = {
      ...form,
      liveSupportId: id,
      sentFrom: "admin",
      userName,
      url: form.file,
      createdDate: dayjs(new Date()).format("YYYY-MM-DD HH:mm:ss"),
      adminSeenDate: dayjs(new Date()).format("YYYY-MM-DD HH:mm:ss"),
    }
    dispatch(addMessage(data)).then(() => {
      dispatch(getLiveSupports());
      socket.emit('refresh_live_supports_user', {
        receiverUser: liveSupport.userName,
      });
      setForm(defaultFormState)
    });
  }

  //send message on enter key
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSubmit();
    }
  }


  const handleFileUpload = (e) => {
    console.log(e.target.files[0]);
    setInForm("file", e.target.files[0]);
  }


  //handle input click on MDButton
  const handleInputClick = () => {
    document.getElementById("file").click();
  }


  return (
    <>
      {form.file && (
        <MDBox sx={{ display: "flex", gap: 1 }}>
          <img src={URL.createObjectURL(form.file)} alt="file" style={{ width: "auto", height: "50px" }} />
        </MDBox>
      )}
      <Box container spacing={1} sx={{ display: "flex", gap: 2 }}>
        <input type="file" id="file" style={{ display: "none" }} onChange={handleFileUpload} />

        <MDButton onClick={handleInputClick} variant="gradient" color="dark">
          <Icon>attach_file</Icon>
        </MDButton>



        <TextField
          variant="outlined"
          type="text"
          label={t("message")}
          fullWidth
          value={form.message}
          onChange={handleChange}
          id="message"
          name="message"
          onKeyDown={handleKeyDown}
        />

        <MDButton onClick={handleSubmit} sx={{ height: "100%" }} variant="gradient" color="info">
          <Icon>send</Icon>
        </MDButton>
      </Box>
    </>
  );
};

export default SendMessageSection;